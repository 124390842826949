import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet';

import QRCodeLP1 from "../components/QRCodeLP1";

import logos from "../assets/images/logos-lp1.webp";
import download from "../assets/images/download.webp";
import desktopPlayers from "../assets/images/mobile-players-lp1.webp";
import mobilePlayers from "../assets/images/mobile-players-lp1.webp";
import rotate from "../assets/images/rotate.png";

import { websiteMetaTagDescription, websiteTitle } from "../constants/htmlStrings";
import { googleAnalyticsTrack } from "../utility/common";

import "../styles/home-styles.css";
import { appDownloadLink } from "../constantsLP1/htmlStrings";

const LP1 = () => {
  useEffect(() => { googleAnalyticsTrack() }, [])

  const handleDownloadButtonClick = () => {
    googleAnalyticsTrack(true);
  }
  return (
    <>
    <div className="mobile-landscape">
      <div className="text-style">Please Rotate Your Device</div>
      <img src={rotate} alt="rotate" className="rotate-icon-style"/>
      </div>
    <div className="main-container">
      <Helmet>
        <title>{websiteTitle}</title>
        <meta name="description" content={websiteMetaTagDescription} />
      </Helmet>
      <div className="container-lp1">
        <div className="sub-container">
          <img alt="logo" src={logos} className="logos-lp1" />
          <img src={mobilePlayers} alt="" className="mobile-players-lp1"/>
          <div className="title-and-button-lp1">
            <div className="mobile-title-lp1">
              <div className="top-text-lp1">Trade Players, Make Teams</div>
              <div className="bottom-text-lp1">WIN LAKHS</div>
            </div>
            <div className="desktop-title-lp1">
              <div className="top-text-lp1">Buy and trade players,<br/>create teams to join contests,</div>
              <div className="bottom-text-lp1">Win Lakhs!</div>
            </div>
            <a href={appDownloadLink} id="mobile-download-button" onClick={handleDownloadButtonClick}  download className="download-link">
              <div className="download-button-lp1" >
                <div className="download-icon-container">
                  <img src={download} alt="" className="download-icon"/>
                </div>
                <div className="download-text">DOWNLOAD APP</div>
              </div>
            </a>
          </div>
          {/* <img src={qrCode} alt="" className="qrCode" /> */}
          <QRCodeLP1 />
        </div>
        <img src={desktopPlayers} alt="" className="desktop-players-lp1" />
      </div>
      <div className="footer-container">
        <div className="footer-nav-lp1">
          <div className="nav-button">
            <Link to="/about-us" className="nav-link">
              About Us
            </Link>
            {/* <a target="_blank" href="https://buystars-qa.s3.ap-south-1.amazonaws.com/aboutUs.html" className="nav-link" rel="noreferrer">
              About Us
            </a> */}
          </div>
          <div className="border"></div>
          <div className="nav-button">
            <Link to="/terms-of-service" className="nav-button">
              Terms of Service
            </Link>
            {/* <a
              className="nav-link"
              href="https://buystars-policies.s3.ap-south-1.amazonaws.com/termsOfUse.html"
              rel="noreferrer"
              target="_blank"
            >
              Terms of Service
            </a> */}
          </div>
          <div className="border"></div>
          <div className="nav-button">
            <Link to="/privacy-policy" className="nav-button">
              Privacy Policy
            </Link>
            {/* <a
              className="nav-link"
              href="https://buystars-policies.s3.ap-south-1.amazonaws.com/privacyPolicy.html"
              rel="noreferrer"
              target="_blank"
            >
              Privacy Policy
            </a> */}
          </div>
        </div>
        <div className="copyright-lp1">
          Copyright &copy; 2022 |&nbsp;
          <div className="copyright-company">Buystars.com</div>
        </div>
      </div>
    </div>
    </>
  );
};

export default LP1;
