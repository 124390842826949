import React from "react";

import app_qr_code_pro from "../assets/images/app_qr_code_pro.webp";
import line from "../assets/images/line.webp";
import rectangle from "../assets/images/rectangle.webp";

import "../styles/qrCode-styles.css";

const QRCodeLP1 = () => {
  return (
    <div  className="qr-container-lp1">
      <div className="qr-image-container">
        <img
          src={rectangle}
          alt=""
          className="blue-rectangle"
        />
        <img
          src={app_qr_code_pro}
          alt=""
          className="qr-image"
        />
      </div>
      <div className="scan-instruction-container">
        <div
          className="scan-text"
        >
          Scan QR code to <br />
          download the app
        </div>
        <img src={line} alt="" className="qr-line"/>
      </div>
    </div>
  );
};

export default QRCodeLP1;
