import React, {useEffect} from "react";
import { Helmet } from 'react-helmet';

import ScreenHeaderBar from "../components/ScreenHeaderBar";
import { aboutUsHtmlString, websiteMetaTagDescription, websiteTitle } from "../constants/htmlStrings";
import { googleAnalyticsTrack } from "../utility/common";

const AboutUs = () => {
  useEffect(() => { googleAnalyticsTrack() }, [])
  return (
    <div>
      <Helmet>
        <title>{websiteTitle}</title>
        <meta name="description" content={websiteMetaTagDescription} />
      </Helmet>
        <ScreenHeaderBar/>
      <div dangerouslySetInnerHTML={{ __html: aboutUsHtmlString }}/>
    </div>
  );
};

export default AboutUs;
