import ReactGA from "react-ga";
import axios from "axios";
import { apkNameMappingProd, apkNameMappingQA } from "../constants/htmlStrings";

export const googleAnalyticsTrackId = "G-H36S9B3E62";
export const googleAnalyticsTrack = (isDownloadButtonClicked = false) => {
  ReactGA.pageview(window.location.pathname + window.location.search);
  if (isDownloadButtonClicked) {
    ReactGA.ga("event", "conversion", {
      send_to: "AW-10861270380/H8KPCOOd1KkDEOyqh7so",
    });
  }
};


function downloadWithProgress(shortName, url, setIsLoading, setProgress) {
  // const startTime = new Date().getTime();

  var request = new XMLHttpRequest();

  request.responseType = "blob";
  request.open("get", url, true);
  request.send();
  setIsLoading(true);

  request.onreadystatechange = function () {
    if (this.readyState === 4 && this.status === 200) {
      const imageURL = window.URL.createObjectURL(this.response);
      const anchor = document.createElement("a");
      const attribution = shortName;
      anchor.href = imageURL;
      anchor.download = attribution?  "BuystarsPro-" + attribution + ".apk" : "BuystarsPro.apk" ;
      document.body.appendChild(anchor);
      anchor.click();
      setIsLoading(false);
    }
  };

  request.onprogress = function (e) {
    const percent_complete = Math.floor((e.loaded / e.total) * 100);

    // const duration = (new Date().getTime() - startTime) / 1000;
    // const bps = e.loaded / duration;

    // const kbps = Math.floor(bps / 1024);

    // const time = (e.total - e.loaded) / bps;
    // const seconds = Math.floor(time % 60);
    // const minutes = Math.floor(time / 60);

    setProgress(percent_complete);
    // console.log(
    //   `${percent_complete}% - ${kbps} Kbps - ${minutes} min ${seconds} sec remaining`
    // );
  };
}

export function downloadApk(env, url, setIsLoading, setProgress) {
  const api = env === "Prod"? apkNameMappingProd : apkNameMappingQA
  axios
    .post(
      api,
      {
        "longName": btoa(window.location.search.substring(1)),
      }
    )
    .then(function (response) {
      console.log(response)
      downloadWithProgress(response.data.shortName, url, setIsLoading, setProgress);
    })
    .catch(function (error) {
      console.log(error);
    });
}

